import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Content from 'components/Content';
import Link, { LinkVariant } from 'components/Link';
import SectionTitle from 'components/SectionTitle';
import List from 'components/List';
import Note, { NoteVariant } from 'components/Note';
import { LINKS } from 'src/utils/constants';
import Accordion, { AccordionVariant } from 'components/Accordion';
import Table from 'components/Table';
import { getChannelsTableHeaders } from 'src/data/tables/channels-table-headers';
import { getExportChannelsTableData } from 'src/data/tables/export-channels-table-data';
import Button, { ButtonVariant } from 'components/Button';
import { useModal } from 'components/Modal/ModalContext';
import ContactForm from 'components/ContactForm';
import FixedWidthCol from 'components/FixedWidthCol';
import {
  ChannelSection,
  CTA,
  InfoSection,
  Hero,
} from './product-information-import';
import { HeadingScope } from 'components/Heading';

const PIEPage: FC = () => {
  const { t } = useTranslation();
  const channelsTableHeaders = getChannelsTableHeaders();
  const importChannelsTabledata = getExportChannelsTableData();
  const { openModal } = useModal();

  return (
    <Layout footerHasMargin>
      <Hero
        image={
          <StaticImage
            src="../assets/images/features/product-information-export/pie-main.jpg"
            alt={t('pie_heading')}
            layout="fullWidth"
          />
        }
        title={t('pie_heading')}
        text={t('pie_text')}
      >
        <Link variant={LinkVariant.CHARLIE} url="#channels">
          <Trans i18nKey="pie_cta" />
        </Link>
      </Hero>
      <HeadingScope>
        <Content>
          <FixedWidthCol>
            <SectionTitle subtitle={t('pie_benefits_subtitle')}>
              <Trans i18nKey="pie_benefits_title" />
            </SectionTitle>
            <List
              items={[
                t('pie_benefits_list_1'),
                t('pie_benefits_list_2'),
                t('pie_benefits_list_3'),
                t('pie_benefits_list_4'),
                t('pie_benefits_list_5'),
              ]}
            />
            <Note variant={NoteVariant.ALFA}>
              <p>
                <Trans i18nKey="pie_benefits_note" />
              </p>
            </Note>
            <Link url={LINKS.REGISTER} variant={LinkVariant.BRAVO}>
              <Trans i18nKey="common_try_free_of_charge" />
            </Link>
          </FixedWidthCol>
          <SectionTitle>
            <Trans i18nKey="pie_export_title" />
          </SectionTitle>
          <InfoSection>
            <StaticImage
              src="../assets/images/features/product-information-export/pie-export-en.jpg"
              alt={t('pie_export_title')}
              width={620}
              height={620}
              quality={100}
            />
            <Accordion
              firstActive
              variant={AccordionVariant.BRAVO}
              items={[
                {
                  title: t('pie_export_1_title'),
                  content: (
                    <>
                      <p>
                        <Trans i18nKey="pie_export_1_text_0" />{' '}
                      </p>
                      <List
                        items={[
                          t('pie_export_1_list_1'),
                          t('pie_export_1_list_2'),
                          t('pie_export_1_list_3'),
                          t('pie_export_1_list_4'),
                          t('pie_export_1_list_5'),
                        ]}
                        columns={2}
                      />
                      <p>
                        <Trans i18nKey="pie_export_1_text_1" />
                        <Button
                          variant={ButtonVariant.CHARLIE}
                          onClick={openModal}
                        >
                          <Trans i18nKey="pie_export_1_text_2" />
                        </Button>
                        <Trans i18nKey="pie_export_1_text_3" />
                      </p>
                    </>
                  ),
                },
                {
                  title: t('pie_export_2_title'),
                  content: (
                    <>
                      <p>
                        <Trans i18nKey="pie_export_2_text" />{' '}
                      </p>
                    </>
                  ),
                },
                {
                  title: t('pie_export_3_title'),
                  content: (
                    <p>
                      <Trans i18nKey="pie_export_3_text" />
                    </p>
                  ),
                },
                {
                  title: t('pie_export_4_title'),
                  content: (
                    <p>
                      <Trans i18nKey="pie_export_4_text" />
                    </p>
                  ),
                },
              ]}
            />
          </InfoSection>
          <SectionTitle subtitle={t('pie_channels_subtitle')} centered>
            <Trans i18nKey={t('pie_channels_title')} />
          </SectionTitle>
          <ChannelSection id="channels">
            <Table
              headers={channelsTableHeaders}
              rows={importChannelsTabledata}
              caption={`${t('index_channels_table_caption_export')}:`}
            />
            <StaticImage
              src="../assets/images/features/product-information-export/pie-table.png"
              alt={t('index_channels_table_caption_export')}
              width={376}
              height={376}
              quality={100}
              placeholder="blurred"
            />
          </ChannelSection>
          <CTA url={LINKS.REGISTER} variant={LinkVariant.BRAVO}>
            <Trans i18nKey="common_try_free_of_charge" />
          </CTA>
        </Content>
        <ContactForm />
      </HeadingScope>
    </Layout>
  );
};

export default PIEPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Product Information Export | OmniGet"
    description="Export product information quickly and accurately with OmniGet. Simplify data sharing and ensure consistency across platforms."
  />
);
